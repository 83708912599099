import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { TextInput } from 'app/components/Inputs/TextInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';

import { messages } from '../../../messages';

interface Props {
	disabled: boolean;
}

export const SwissOptions = ({ disabled }: Props) => {
	const { t } = useTranslation();

	return (
		<Grid container spacing={1}>
			<FormInputGrid item sm={6}>
				<FormLabel>{'Iban Postfinance'}</FormLabel>
				<TextInput
					disabled={disabled}
					name="ibanPostfinance"
					placeholder={t(messages.iban.placeholder())}
				/>
			</FormInputGrid>
			<FormInputGrid item sm={6}>
				<FormLabel>{'Identifiant RSPID'}</FormLabel>
				<TextInput disabled={disabled} name="idRsPid" />
			</FormInputGrid>
			<FormInputGrid item sm={6}>
				<FormLabel>{'Identifiant LSV+'}</FormLabel>
				<TextInput disabled={disabled} name="idLsvPlus" />
			</FormInputGrid>
		</Grid>
	);
};
