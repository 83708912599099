export type PrelevementInput = {
	institut: {
		nom: string;
		id: string;
		iban: string;
		bank_id: string;
	};
	date_payment: string;
	transactions: {
		id: string;
		amount: number;
		debtor: {
			name: string;
			address: string;
			iban: string;
		};
	}[];
};

//The message id must be unique for all message sent within the year
export const generateMessageId = () => {
	const now = new Date();
	const random = Math.floor(Math.random() * 10000000)
		.toString()
		.padStart(8, '0');
	return `${now.getFullYear()}${now.getMonth()}${now.getDate()}${random}`;
};

//Must be under the format 2024-01-01T00:00:00+01:00, for this we're assuming the document is always generated on the GMT+1 timezone
export const generateCreationDateTime = () => {
	const now = new Date();
	return now.toISOString().slice(0, -5) + '+01:00';
};

//Sum of all the transactions amount
export const generateControlSum = (transactions: { amount: number }[]) => {
	return transactions.reduce((acc, t) => acc + t.amount, 0).toFixed(2);
};

//Must be an unique identifier within the document itself
//The format is the name of the institute, followed by the date of generation
export const generatePaymentMessageId = (prelevement: PrelevementInput) => {
	const now = new Date();
	return `${prelevement.institut.nom.toUpperCase()}-${now.getFullYear()}${now.getMonth()}${now.getDate()}`;
};

//Should be under the format 2024-01-01
export const generateCollectionDate = (date: string) => {
	const d = new Date(date);
	return d.toISOString().split('T')[0];
};

export const extractCountryFromIBAN = (iban: string) => {
	return iban.slice(0, 2);
};

//The clearing key is the 5th to 9th characters of the IBAN
export const extractClearingKeyFromIBAN = (iban: string) => {
	return iban.slice(4, 9);
};

//Remove accents and other diacritics from a string and convert it to uppercase
export const normalizeDiacritics = (str: string) => {
	return str
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')
		.toUpperCase();
};

//Le numéro de référence BVR est un identifiant unique de 27 caractères
//On le génère de la méthode suivante :
//1. On prends l'id de l'institut et on le pad avec des 0 pour obtenir 5 caractères
//2. On prends l'id de l'abonné et on le pad avec des 0 pour obtenir 7 caractères
//3. On prends la date de paiement et on la formate en JJMM
//4. On combine les 3 éléments pour obtenir le numéro de référence
//6. On pad la string complète avec des 0 pour obtenir 27 caractères
//5. On calcule le chiffre de controle en utilisant le checksum mod 10 (variante de Luhn)
export const generateBVRNumber = (
	institutId: string,
	transaction: PrelevementInput['transactions'][number],
) => {
	const instituteId = institutId.padStart(5, '0');
	const subscriberId = transaction.id.padStart(7, '0');
	const date =
		new Date().toISOString().slice(8, 10) + new Date().toISOString().slice(5, 7);
	const reference = `${instituteId}${subscriberId}${date}`.padStart(26, '0');
	const control = calcDigits(reference);
	const fullReference = `${reference}${control}`;
	return fullReference;
};

//Algo de check non conforme
const calcDigits = (number: string) => {
	const table = [0, 9, 4, 6, 8, 2, 7, 1, 3, 5];
	let carry = 0;

	for (var i = 0; i < number.length; i++) {
		const c = parseInt(number.substring(i, i + 1), 10);
		const index = (carry + c) % 10;

		carry = table[index];
	}
	return (10 - carry) % 10;
};
