import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';

import { FormLabel } from 'app/components/Forms/FormLabel';
import { TextInput } from 'app/components/Inputs/TextInput';
import { FormInputGrid } from 'app/components/Forms/FormInputGrid';

import { messages } from '../../../messages';

interface Props {
	disabled: boolean;
}

export const BankAccount = ({ disabled }: Props) => {
	const { t } = useTranslation();

	return (
		<Grid container spacing={1}>
			<FormInputGrid item sm={6}>
				<FormLabel>{t(messages.iban.label())}</FormLabel>
				<TextInput
					disabled={disabled}
					name="iban"
					placeholder={t(messages.iban.placeholder())}
					required
				/>
			</FormInputGrid>

			<FormInputGrid item sm={6}>
				<FormLabel>{t(messages.ics.label())}</FormLabel>
				<TextInput
					disabled={disabled}
					name="ics"
					placeholder={t(messages.ics.placeholder())}
				/>
			</FormInputGrid>

			<FormInputGrid item sm={6}>
				<FormLabel>{t(messages.bic.label())}</FormLabel>
				<TextInput
					disabled={disabled}
					name="bic"
					placeholder={t(messages.bic.placeholder())}
				/>
			</FormInputGrid>

			<FormInputGrid item sm={6}>
				<FormLabel>{t(messages.alma.label())}</FormLabel>
				<TextInput
					disabled={disabled}
					name="almaKey"
					placeholder={t(messages.alma.placeholder())}
				/>
			</FormInputGrid>
		</Grid>
	);
};
